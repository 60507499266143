import { gql } from '@apollo/client';

const CORE_PROFILE_FIELDS = gql`
  fragment CoreProfileFields on profiles {
    nodeId
    id
    first_name
    last_name
    phone_number
    date_of_birth
    avatar_url
    gender
    opt_in_notifications
    account_id
    account {
      nodeId
      id
      salesforce_account_id
      account_name
      abn
      billing_country
      billing_street
      billing_city
      billing_state
      billing_postal_code
      first_name
      last_name
      contact_email
      invoice_email
      phone
      provider_type
      rto_code
      managed_through_self_service
      new_business_flag
      parent_id
      status
      author
      salesforce_sync_status_account
      salesforce_sync_status_brands
      is_migrated_from_salesforce
      should_trigger_notification
      qc_note
      last_published_id
      qc_status
      system_note
      is_fully_synced
    }
  }
`;

export const GET_PROFILES = gql`
  ${CORE_PROFILE_FIELDS}
  query GetProfiles($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    profilesCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreProfileFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_PROFILES = gql`
  ${CORE_PROFILE_FIELDS}
  query FilteredProfiles(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $ids: [String]
    $first_name: String
    $last_name: String
    $phone_number: String
    $account_id: Int
  ) {
    profilesCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: {
        or: {
          id: { in: $ids }
          first_name: { ilike: $first_name }
          last_name: { ilike: $last_name }
          phone_number: { eq: $phone_number }
          account_id: { eq: $account_id }
        }
      }
    ) {
      edges {
        cursor
        node {
          ...CoreProfileFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_PROFILE_BY_NODE_ID = gql`
  ${CORE_PROFILE_FIELDS}
  query GetProfileByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on profiles {
        ...CoreProfileFields
      }
    }
  }
`;

export const GET_PROFILE_BY_ID = gql`
  ${CORE_PROFILE_FIELDS}
  query GetProfileById($id: UUID!) {
    profilesCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreProfileFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_PROFILE = gql`
  ${CORE_PROFILE_FIELDS}
  mutation CreateProfile(
    $first_name: String
    $last_name: String
    $phone_number: String
    $date_of_birth: Date
    $avatar_url: String
    $gender: String
  ) {
    insertIntoprofilesCollection(
      objects: [
        {
          first_name: $first_name
          last_name: $last_name
          phone_number: $phone_number
          date_of_birth: $date_of_birth
          avatar_url: $avatar_url
          gender: $gender
        }
      ]
    ) {
      affectedCount
      records {
        ...CoreProfileFields
      }
    }
  }
`;

export const UPDATE_PROFILE_BY_ID = gql`
  ${CORE_PROFILE_FIELDS}
  mutation UpdateProfileById(
    $nodeId: ID
    $id: UUID
    $first_name: String
    $last_name: String
    $phone_number: String
    $date_of_birth: Date
    $avatar_url: String
    $gender: String
    $account_id: Int
    $opt_in_notifications: Boolean
  ) {
    updateprofilesCollection(
      set: {
        first_name: $first_name
        last_name: $last_name
        phone_number: $phone_number
        date_of_birth: $date_of_birth
        avatar_url: $avatar_url
        gender: $gender
        account_id: $account_id
        opt_in_notifications: $opt_in_notifications
      }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreProfileFields
      }
    }
  }
`;

export const DELETE_PROFILE_BY_ID = gql`
  mutation DeleteProfileById($nodeId: ID, $id: UUID) {
    deleteFromprofilesCollection(filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }) {
      affectedCount
      records {
        id
        first_name
      }
    }
  }
`;
