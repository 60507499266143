import type { RootState, AppDispatch } from '@redux/index';
import type { INotification } from 'src/types/notification';

import { toast } from 'sonner';
import { supabase } from '@lib/supabase';
import { stringAvatar } from '@utils/avatar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFilteredBrands } from '@redux/features/brand';
import { fetchFilteredNotifications } from '@redux/features/notification';
import { setNoticesStateData } from '@redux/features/notification/noticesData';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

import { useRouter } from 'src/routes/hooks';

import { fToNow } from 'src/utils/format-time';

import { Label } from 'src/components/label';

// ----------------------------------------------------------------------

interface Props {
  notification: Partial<INotification>;
  // eslint-disable-next-line react/no-unused-prop-types
  drawer?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  type?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  item?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  handleCloseSelector?: () => void;
}

export const NotificationItem = ({
  notification,
  drawer,
  type,
  item,
  handleCloseSelector,
}: Props) => {
  // ** Hooks
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();

  // ** Vars
  const { account_id, brand_id, course_id, read_by } = notification;
  const isCourse = course_id !== null;
  const isBrand = brand_id !== null && course_id === null;
  const isAccount = account_id !== null && brand_id === null && course_id === null;
  const isRead = read_by !== null;
  const isPopup = type === 'popup';

  const color = isCourse
    ? 'var(--palette-primary-main)'
    : isBrand
      ? 'var(--palette-success-main)'
      : 'var(--palette-warning-main)';

  const handleNotificationRedirect = async () => {
    // Close notifications drawer
    drawer.onFalse();

    // If course notification, route to the course page
    if (isCourse) {
      // Set brand_id to localStorage
      window.localStorage.setItem('bId', String(brand_id));

      router.push(`/dashboard/${brand_id}/course/${course_id}/edit`);
      router.refresh();
    }

    // If brand notification, route to the brand page
    if (isBrand) {
      // Set brand_id to localStorage
      window.localStorage.setItem('bId', String(brand_id));

      router.push(`/dashboard/${brand_id}/client/brand/edit`);
      router.refresh();
    }

    // If account notification, route to the account page
    if (isAccount) {
      const firstBrand = await dispatch(
        fetchFilteredBrands({
          first: 1,
          account_id,
        })
      );

      const {
        brandCollection: { edges },
      }: any = firstBrand.payload;

      const firstBrandId = edges[0].node.id;

      // Set brand_id to localStorage
      window.localStorage.setItem('bId', String(firstBrandId));

      router.push(`/dashboard/${firstBrandId}/client/account`);
      router.refresh();
    }
  };

  const renderAvatar = (
    <ListItemAvatar>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 36,
          height: 36,
          borderRadius: '50%',
          bgcolor: isRead ? 'var(--palette-grey-300)' : color,
        }}
      >
        {notification?.brand?.logo ? (
          <Avatar
            src={notification?.brand?.logo}
            sx={{ width: 32, height: 32, bgcolor: isRead ? 'var(--palette-grey-300)' : color }}
          />
        ) : (
          <Avatar
            {...stringAvatar(`${notification?.account?.account_name}`)}
            sx={{
              width: 32,
              height: 32,
              fontSize: 18,
              bgcolor: isRead ? 'var(--palette-grey-300)' : color,
            }}
          />
        )}
      </Stack>
    </ListItemAvatar>
  );

  const renderText = (
    <Stack spacing={2} paddingInlineEnd={isPopup ? 0 : 2}>
      <ListItemText
        disableTypography
        primary={reader(notification, handleNotificationRedirect)}
        secondary={
          <Stack
            direction="row"
            alignItems="center"
            sx={{ typography: 'caption', color: 'text.disabled' }}
            divider={
              <Box
                sx={{
                  width: 2,
                  height: 2,
                  bgcolor: isRead ? 'var(--palette-grey-300)' : 'currentColor',
                  mx: 0.5,
                  borderRadius: '50%',
                }}
              />
            }
          >
            {fToNow(notification.created_at)}

            <Label
              variant="outlined"
              color={isRead ? 'default' : isCourse ? 'primary' : isBrand ? 'success' : 'warning'}
            >
              {isCourse && 'Course'}
              {isBrand && 'Brand'}
              {isAccount && 'Account'}
            </Label>

            <Label
              variant={isRead ? 'soft' : 'outlined'}
              color={
                isRead
                  ? 'secondary'
                  : notification.notification_type === 'todo'
                    ? 'error'
                    : notification.notification_type
              }
            >
              {isRead ? 'Done' : notification.notification_type}
            </Label>

            {isCourse && (
              <Typography
                variant="subtitle2"
                fontWeight={400}
                sx={{
                  color: isRead ? 'var(--palette-grey-300)' : 'var(--palette-grey-500)',
                  textDecoration: isRead ? 'line-through' : 'none',
                }}
              >
                {notification.brand?.provider_name}
              </Typography>
            )}
          </Stack>
        }
      />
    </Stack>
  );

  const renderUnReadBadge = !isPopup && notification.read_by === null && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: isPopup ? 'none' : (theme) => `dashed 1px ${theme.vars.palette.divider}`,
        cursor: 'default',
        ...(isPopup && { '&:hover': { backgroundColor: 'transparent' } }),
      }}
    >
      {renderUnReadBadge}

      {renderAvatar}

      <Stack sx={{ flexGrow: 1 }}>
        {renderText}

        <ReadAction
          notification={notification}
          isPopup={isPopup}
          item={item}
          drawer={drawer}
          handleCloseSelector={handleCloseSelector}
        />
      </Stack>
    </ListItemButton>
  );
};

// ----------------------------------------------------------------------

const ReadAction = ({
  notification,
  isPopup,
  item,
  drawer,
  handleCloseSelector,
}: Props & { isPopup: boolean }) => {
  // ** Hooks
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const { activeBrand } = useSelector((state: RootState) => state.activeBrand);
  const { authSession } = useSelector((state: RootState) => state.authSession);

  // ** Vars
  const isClientRole = authSession?.role[0]?.includes('client');
  const { id, read_by, notification_type, brand_id } = notification;
  const read = read_by !== null;
  const todo = notification_type === 'todo';

  const fetchNotifications = async () => {
    const notices = await dispatch(
      fetchFilteredNotifications({
        first: 100,
        ...(isClientRole
          ? { account_id: activeBrand.account_id, visibility: 'external' }
          : { visibility: 'internal' }),
      })
    );

    const {
      notificationCollection: { edges, pageInfo },
    }: any = notices.payload;

    await dispatch(
      setNoticesStateData({
        notifications: edges?.map((i: any) => i?.node),
        notificationsPageInfo: pageInfo,
      })
    );
  };

  const handleMarkRead = async () => {
    const { data } = await supabase.rpc('mark_notification_as_read', {
      p_notification_id: id,
    });

    // If the function returns a boolean
    if (data === true) {
      toast.success('Notification marked as READ successfully!');

      fetchNotifications();

      if (isPopup) {
        if (handleCloseSelector) handleCloseSelector();
        if (item === 'course') router.push(`/dashboard/${brand_id}/course/list`);
      } else {
        drawer.onFalse();
      }
    } else {
      toast.warning('Notification was NOT marked as READ (possibly already read or not found)!');
      console.log('Notification was NOT marked as READ (possibly already read or not found)!');
    }
  };

  return (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      {!read && (
        <Button
          size="small"
          variant="contained"
          color="inherit"
          onClick={handleMarkRead}
          sx={{
            '& .MuiTypography-root': {
              color: 'var(--palette-common-white)',
            },
          }}
        >
          <Typography
            variant="caption"
            color="var(--palette-common-black)"
            fontWeight={600}
          >{`Mark ${todo ? 'Done' : 'Read'}`}</Typography>
        </Button>
      )}
    </Stack>
  );
};

// ----------------------------------------------------------------------

const reader = (data: any, handleNotificationRedirect: () => void) => {
  const { read_by } = data;
  const isRead = read_by !== null;

  const title = data.title.split(':')[0];
  const entity = data.title.split(':')[1];

  return (
    <>
      <Link
        onClick={isRead ? () => null : handleNotificationRedirect}
        sx={{
          color: isRead ? 'var(--palette-grey-300)' : 'var(--palette-text-primary)',
          cursor: isRead ? 'default' : 'pointer',
          '&:hover': { textDecoration: isRead ? 'none' : 'underline' },
        }}
      >
        <Typography variant="subtitle2" sx={{ textDecoration: isRead ? 'line-through' : 'none' }}>
          <span
            style={{ color: isRead ? 'var(--palette-grey-300)' : 'var(--palette-text-primary)' }}
          >
            {title}:{' '}
          </span>
          <span
            style={{
              color: isRead ? 'var(--palette-grey-300)' : 'var(--palette-grey-500)',
              textDecoration: isRead ? 'line-through' : 'underline',
            }}
          >
            {entity}
          </span>
        </Typography>
      </Link>

      <Typography
        variant="body2"
        mb={1}
        color={isRead ? 'var(--palette-grey-300)' : 'var(--palette-grey-700)'}
        sx={{ textDecoration: isRead ? 'line-through' : 'none' }}
      >
        {data.content}
      </Typography>
    </>
  );
};
