import type { RootState, AppDispatch } from '@redux/index';
import type { IconButtonProps } from '@mui/material/IconButton';

import { m } from 'framer-motion';
import { ICONS } from '@layouts/config-nav-dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState, useEffect, useCallback } from 'react';
import { fetchFilteredNotifications } from '@redux/features/notification';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { useBoolean } from 'src/hooks/use-boolean';

import { Label } from 'src/components/label';
import { Iconify } from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { Scrollbar } from 'src/components/scrollbar';
import { CustomTabs } from 'src/components/custom-tabs';

import { Filter } from './filter';
import { NotificationItem } from './notification-item';

// ----------------------------------------------------------------------

interface TabItem {
  value: string;
  label: string;
  count: number;
}

// ----------------------------------------------------------------------

export type NotificationsDrawerProps = IconButtonProps & {
  data?: any;
};

export const NotificationsDrawer = ({ sx, ...other }: NotificationsDrawerProps) => {
  // ** States
  const [currentTab, setCurrentTab] = useState<string>('all');
  const [notificationsData, setNotificationsData] = useState<any[]>([]);
  const [notificationsDataArray, setNotificationsDataArray] = useState<any[]>([]);
  const [notificationsDataPageInfo, setNotificationsDataPageInfo] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [readStatus, setReadStatus] = useState<boolean>(false);

  // ** Hooks
  const drawer = useBoolean();
  const dispatch = useDispatch<AppDispatch>();
  const observer = useRef<IntersectionObserver | null>(null);
  const { activeBrand } = useSelector((state: RootState) => state.activeBrand);
  const { authSession } = useSelector((state: RootState) => state.authSession);
  const { noticesStateData } = useSelector((state: RootState) => state.noticesStateData);

  // ** Vars
  const isClientRole = authSession?.role[0]?.includes('client');
  const { notifications, notificationsPageInfo } = noticesStateData;
  const noticesArray = value
    ? notificationsDataArray.length > 0
      ? notificationsDataArray
      : notifications
    : notifications;
  const totalUnRead = noticesArray.filter((item: any) => item.read_by === null).length;
  const totalTodo = noticesArray.filter((item: any) => item.notification_type === 'todo');
  const unreadTodo = totalTodo.filter((item: any) => item.read_by === null).length;
  const totalInfo = noticesArray.filter((item: any) => item.notification_type === 'info');
  const unreadInfo = totalInfo.filter((item: any) => item.read_by === null).length;
  const totalError = noticesArray.filter((item: any) => item.notification_type === 'error');
  const unreadError = totalError.filter((item: any) => item.read_by === null).length;
  const totalWarning = noticesArray.filter((item: any) => item.notification_type === 'warning');
  const unreadWarning = totalWarning.filter((item: any) => item.read_by === null).length;
  const totalSuccess = noticesArray.filter((item: any) => item.notification_type === 'success');
  const unreadSuccess = totalSuccess.filter((item: any) => item.read_by === null).length;
  const unRead = notificationsData.filter((item: any) => item.read_by === null);
  const read = notificationsData.filter((item: any) => item.read_by !== null);
  const TABS: TabItem[] = [
    { value: 'all', label: 'All', count: totalUnRead },
    ...(totalTodo.length > 0 ? [{ value: 'todo', label: 'Todo', count: unreadTodo }] : []),
    ...(totalInfo.length > 0 ? [{ value: 'info', label: 'Info', count: unreadInfo }] : []),
    ...(totalError.length > 0 ? [{ value: 'error', label: 'Error', count: unreadError }] : []),
    ...(totalWarning.length > 0
      ? [{ value: 'warning', label: 'Warning', count: unreadWarning }]
      : []),
    ...(totalSuccess.length > 0
      ? [{ value: 'success', label: 'Success', count: unreadSuccess }]
      : []),
  ];

  const sortArray = (arr: any[]) =>
    [...arr].sort((a, b) => {
      // Step 1: Sort by `read_by`, bringing items with `read_by` as null to the front
      if (a.read_by === null && b.read_by !== null) return -1;
      if (a.read_by !== null && b.read_by === null) return 1;

      // Step 2: If both are the same for `read_by`, sort by `created_at`
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });

  useEffect(() => {
    if (notifications.length > 0) {
      const sortedArray = sortArray(notifications);

      setNotificationsData(sortedArray);
      setNotificationsDataPageInfo(notificationsPageInfo);
    }
  }, [notifications, notificationsPageInfo]);

  const handleSetValue = (val: string) => {
    setValue(val);
  };

  const handleFilter = useCallback(async () => {
    setIsLoading(true);

    setCurrentTab('all');

    // Fetch notifications
    if (activeBrand.nodeId) {
      const notices = await dispatch(
        fetchFilteredNotifications({
          first: 100,
          ...(isClientRole
            ? { account_id: activeBrand.account_id, visibility: 'external' }
            : { visibility: 'internal' }),
          title: `%${value}%`,
        })
      );

      const {
        notificationCollection: { edges, pageInfo },
      }: any = notices.payload;

      const noticesData = edges?.map((item: any) => item?.node);

      const sortedArray = sortArray(noticesData);

      setNotificationsData(sortedArray);
      setNotificationsDataArray(sortedArray);
      setNotificationsDataPageInfo(pageInfo);

      setPage(0);
    }

    setIsLoading(false);
  }, [activeBrand.account_id, activeBrand.nodeId, dispatch, isClientRole, value]);

  const handleFilterRead = () => {
    setReadStatus(!readStatus);
  };

  const handleChangeTab = useCallback(
    async (event: React.SyntheticEvent, newValue: string, hasSearchTerm: boolean = false) => {
      setIsLoading(true);

      if (hasSearchTerm) setValue('');
      setCurrentTab(newValue);

      // Fetch notifications
      if (activeBrand.nodeId) {
        const notices = await dispatch(
          fetchFilteredNotifications({
            first: 100,
            ...(isClientRole
              ? { account_id: activeBrand.account_id, visibility: 'external' }
              : { visibility: 'internal' }),
            ...(newValue !== 'all' && { notification_type: newValue }),
            ...(!hasSearchTerm && value && { title: `%${value}%` }),
          })
        );

        const {
          notificationCollection: { edges, pageInfo },
        }: any = notices.payload;

        const noticesData = edges?.map((item: any) => item?.node);

        const sortedArray = sortArray(noticesData);

        setNotificationsData(sortedArray);
        setNotificationsDataPageInfo(pageInfo);
      }

      setIsLoading(false);
    },
    [activeBrand.account_id, activeBrand.nodeId, dispatch, isClientRole, value]
  );

  const handlePagination = useCallback(
    async (pageNum: number) => {
      setIsLoadingMore(true);

      // Fetch notifications
      if (activeBrand.nodeId) {
        const hasNextPage = notificationsDataPageInfo?.hasNextPage || null;
        const loadNextPage = hasNextPage && pageNum > page;

        const notices = await dispatch(
          fetchFilteredNotifications({
            first: 100,
            ...(isClientRole
              ? { account_id: activeBrand.account_id, visibility: 'external' }
              : { visibility: 'internal' }),
            ...(value && { title: `%${value}%` }),
            ...(currentTab !== 'all' && { notification_type: currentTab }),
            ...(loadNextPage && { after: notificationsDataPageInfo?.endCursor }),
          })
        );

        const {
          notificationCollection: { edges, pageInfo },
        }: any = notices.payload;

        const noticesData = edges?.map((item: any) => item?.node);

        const sortedArray = sortArray([...notificationsData, ...noticesData]);

        setNotificationsData(sortedArray);
        setNotificationsDataPageInfo(pageInfo);
      }

      setIsLoadingMore(false);
    },
    [
      activeBrand.account_id,
      activeBrand.nodeId,
      currentTab,
      dispatch,
      isClientRole,
      notificationsData,
      notificationsDataPageInfo?.endCursor,
      notificationsDataPageInfo?.hasNextPage,
      page,
      value,
    ]
  );

  // Load more options when reaching the last item
  const lastOptionRef = useCallback(
    (node: HTMLLIElement) => {
      if (isLoadingMore) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && notificationsDataPageInfo?.hasNextPage) {
          setPage((prevPage) => prevPage + 1);
          handlePagination(page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [handlePagination, isLoadingMore, notificationsDataPageInfo?.hasNextPage, page]
  );

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, px: 2.5, height: 90 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      <Filter
        value={value}
        handleSetValue={handleSetValue}
        handleFilter={handleFilter}
        handleFetchNotifications={handleChangeTab}
      />

      <IconButton onClick={drawer.onFalse} sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>

      <Stack direction="row" spacing={0} justifyContent="center" alignItems="center">
        <Typography variant="subtitle2">Unread</Typography>

        <Switch name="switch" checked={readStatus} onChange={handleFilterRead} />

        <Typography variant="subtitle2">Read</Typography>
      </Stack>
    </Stack>
  );

  const renderTabs = (
    <CustomTabs variant="fullWidth" value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'todo' && 'error') ||
                (tab.value === 'info' && 'info') ||
                (tab.value === 'warning' && 'warning') ||
                (tab.value === 'error' && 'error') ||
                (tab.value === 'success' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
        />
      ))}
    </CustomTabs>
  );

  const renderNotAvailable = (
    <Stack direction="row" justifyContent="center" alignItems="center" height="75vh">
      <Typography variant="subtitle2">No notifications available</Typography>
    </Stack>
  );

  const renderList = (
    <Scrollbar>
      <Box component="ul">
        {isLoading ? (
          <Stack direction="row" justifyContent="center" alignItems="center" height="75vh">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {notificationsData.length > 0 ? (
              <>
                {!readStatus
                  ? unRead.length > 0
                    ? unRead.map((notification, index) => {
                        const lastItem = index === notificationsData.length - 1;

                        return (
                          <Box
                            component="li"
                            key={index}
                            sx={{ display: 'flex' }}
                            ref={lastItem ? lastOptionRef : null}
                          >
                            <NotificationItem notification={notification} drawer={drawer} />
                          </Box>
                        );
                      })
                    : renderNotAvailable
                  : readStatus
                    ? read.length > 0
                      ? read.map((notification, index) => {
                          const lastItem = index === notificationsData.length - 1;

                          return (
                            <Box
                              component="li"
                              key={index}
                              sx={{ display: 'flex' }}
                              ref={lastItem ? lastOptionRef : null}
                            >
                              <NotificationItem notification={notification} drawer={drawer} />
                            </Box>
                          );
                        })
                      : renderNotAvailable
                    : notificationsData?.map((notification, index) => {
                        const lastItem = index === notificationsData.length - 1;

                        return (
                          <Box
                            component="li"
                            key={index}
                            sx={{ display: 'flex' }}
                            ref={lastItem ? lastOptionRef : null}
                          >
                            <NotificationItem notification={notification} drawer={drawer} />
                          </Box>
                        );
                      })}
              </>
            ) : (
              renderNotAvailable
            )}
          </>
        )}
      </Box>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={drawer.onTrue}
        sx={sx}
        {...other}
      >
        <Badge badgeContent={totalUnRead} color="error">
          {ICONS.notification}
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{
          sx: {
            width: 1,
            maxWidth: TABS.length > 4 ? 600 : TABS.length > 5 ? 650 : 550,
          },
        }}
      >
        {renderHead}

        {renderTabs}

        {renderList}

        <Box sx={{ p: 1 }}>
          {isLoadingMore && (
            <Stack direction="row" justifyContent="center" alignItems="center" my={2}>
              <CircularProgress size={4} />
            </Stack>
          )}
        </Box>
      </Drawer>
    </>
  );
};
