import { paths } from 'src/routes/paths';

import packageJson from '../package.json';

// ----------------------------------------------------------------------

type BranchConfigKeys = 'VITE_SUPABASE_URL' | 'VITE_SUPABASE_ANON_KEY' | 'VITE_WP_PREVIEW_URL';

const getBranchConfig = (branch: string): Partial<Record<BranchConfigKeys, string>> => {
  if (!branch) {
    return {};
  }

  const BRANCHMAP: Record<string, string> = {};

  // Convert branch name to uppercase for env var convention
  const branchPrefix = (BRANCHMAP[branch] ?? branch).toUpperCase();

  // Generate configuration dynamically
  const configKeys: BranchConfigKeys[] = [
    'VITE_SUPABASE_URL',
    'VITE_SUPABASE_ANON_KEY',
    'VITE_WP_PREVIEW_URL',
  ];

  // Filter out undefined values
  return configKeys.reduce(
    (acc, key) => {
      const suffix = key.replace('VITE_', '');
      const envKey = `VITE_${branchPrefix}_${suffix}`;
      const value = import.meta.env[envKey as string];

      if (typeof value === 'string') {
        acc[key] = value;
      }
      return acc;
    },
    {} as Partial<Record<BranchConfigKeys, string>>
  );
};

// Get branch-specific config
const branchConfig = getBranchConfig(import.meta.env.VITE_CF_PAGES_BRANCH || '');

// ----------------------------------------------------------------------

export const CONFIG = {
  site: {
    name: 'Candlefox Marketing Hub',
    serverUrl: import.meta.env.VITE_SERVER_URL ?? '',
    assetURL: import.meta.env.VITE_ASSET_URL ?? '',
    basePath: import.meta.env.VITE_BASE_PATH ?? '',
    currentEnv: import.meta.env.VITE_CF_PAGES_BRANCH ?? '',
    version: packageJson.version,
  },
  /**
   * Auth
   * @method jwt | amplify | firebase | supabase | auth0
   */
  auth: {
    method: 'supabase',
    skip: false,
    redirectPath: paths.dashboard.root,
  },
  /**
   * Mapbox
   */
  mapbox: {
    apiKey: import.meta.env.VITE_MAPBOX_API_KEY ?? '',
  },
  /**
   * Firebase
   */
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY ?? '',
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN ?? '',
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID ?? '',
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET ?? '',
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID ?? '',
    appId: import.meta.env.VITE_FIREBASE_APPID ?? '',
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID ?? '',
  },
  /**
   * Amplify
   */
  amplify: {
    userPoolId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_ID ?? '',
    userPoolWebClientId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID ?? '',
    region: import.meta.env.VITE_AWS_AMPLIFY_REGION ?? '',
  },
  /**
   * Auth0
   */
  auth0: {
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID ?? '',
    domain: import.meta.env.VITE_AUTH0_DOMAIN ?? '',
    callbackUrl: import.meta.env.VITE_AUTH0_CALLBACK_URL ?? '',
  },
  /**
   * Supabase
   */
  supabase: {
    url: branchConfig.VITE_SUPABASE_URL ?? import.meta.env.VITE_SUPABASE_URL ?? '',
    key: branchConfig.VITE_SUPABASE_ANON_KEY ?? import.meta.env.VITE_SUPABASE_ANON_KEY ?? '',
  },
  /**
   * Google
   */
  googleMaps: {
    apiKey: import.meta.env.GOOGLE_MAPS_API_KEY ?? 'AIzaSyA2_c8IYGCPjvy0up0j5qEm-boGwh_HOO8',
  },

  wordpress: {
    previewUrl: branchConfig.VITE_WP_PREVIEW_URL ?? import.meta.env.VITE_WP_PREVIEW_URL ?? '',
  },
};
