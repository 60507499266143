import type { AppDispatch } from '@redux/index';

import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useMemo, useEffect, useCallback } from 'react';
import { setAuthSession } from '@redux/features/auth/session';

import { useSetState } from 'src/hooks/use-set-state';

import axios from 'src/utils/axios';

import { supabase } from 'src/lib/supabase';

import { AuthContext } from '../auth-context';

import type { AuthState, JWTDecodedPayload } from '../../types';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
  // ** States
  const { state, setState } = useSetState<AuthState>({
    user: null,
    loading: true,
  });

  // ** Hooks
  const dispatch = useDispatch<AppDispatch>();

  const updateUserSession = useCallback(
    async (session: any) => {
      try {
        if (session) {
          const accessToken = session?.access_token;

          const jwt = jwtDecode<JWTDecodedPayload>(accessToken);

          const role = jwt.user_roles;
          const capabilities = jwt.user_permissions;

          // Set global state
          await dispatch(setAuthSession({ role, capabilities }));

          setState({ user: { ...session, ...session?.user }, loading: false });
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        } else {
          setState({ user: null, loading: false });
          delete axios.defaults.headers.common.Authorization;
        }
      } catch (error) {
        console.error(error);
        setState({ user: null, loading: false });
      }
    },
    [dispatch, setState]
  );

  useEffect(() => {
    let mounted = true;

    // Fetch initial session
    (async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();

      if (mounted) {
        if (error) {
          setState({ user: null, loading: false });
          console.error(error);
          throw error;
        }

        if (session) updateUserSession(session);
      }
    })();

    // Subscribe to auth session state
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, newSession) => {
      if (!mounted) return;

      // Handle token refresh event
      if (event === 'TOKEN_REFRESHED') {
        updateUserSession(newSession);
      }

      // Update session when auth state changes
      updateUserSession(newSession);
    });

    return () => {
      mounted = false;
      subscription?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user
        ? {
            ...state.user,
            id: state.user?.id,
            accessToken: state.user?.access_token,
            displayName: `${state.user?.user_metadata.display_name}`,
            role: state.user?.role ?? 'admin',
          }
        : null,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
    }),
    [state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};
