import { gql } from '@apollo/client';

const CORE_BRAND_FIELDS = gql`
  fragment CoreBrandFields on brand {
    nodeId
    id
    salesforce_provider_id
    active
    provider_name
    provider_display_name
    course_guide_hosted
    post_enquiry_sms_enabled
    post_enquiry_sms_content
    email_leads_enabled
    lead_notification_email
    airtable_id
    integration_enabled
    about_provider
    logo
    provider_lead_bundles_tnc
    provider_ecomm_tnc
    valid_cc_for_provider_ecomm
    auto_pausing_enabled
    spend_cap
    remaining_spend
    provider_category
    budget_alerts_email
    send_leads_to_countries
    all_courses_published_client
    all_courses_published_candlefox
    api_id_required
    grouping_required
    course_code_required
    account_id
    parent_id
    status
    author
    salesforce_sync_status_courses
    wordpress_sync_status
    provider_pause_reason
    is_migrated_from_salesforce
    should_trigger_notification
    qc_note
    last_published_id
    qc_status
    website
    phone
    system_note
    system_pause_reason
    rto_code
    account {
      nodeId
      id
      salesforce_account_id
      account_name
      abn
      billing_country
      billing_street
      billing_city
      billing_state
      billing_postal_code
      first_name
      last_name
      contact_email
      invoice_email
      phone
      provider_type
      rto_code
      managed_through_self_service
      new_business_flag
      parent_id
      status
      author
      salesforce_sync_status_account
      salesforce_sync_status_brands
      is_migrated_from_salesforce
      should_trigger_notification
      qc_note
      last_published_id
      qc_status
      system_note
      is_fully_synced
    }
  }
`;

export const GET_BRANDS = gql`
  ${CORE_BRAND_FIELDS}
  query GetBrands($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    brandCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreBrandFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_BRANDS = gql`
  ${CORE_BRAND_FIELDS}
  query FilteredBrands(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $orderBy: any
    $account_id: Int
    $provider_name: String
    $provider_display_name: String
    $spend_cap: String
    $provider_category: String
    $status: String
    $not_status: String
    $website: String
    $phone: String
    $parent_id: Int
  ) {
    brandCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      filter: {
        or: {
          account_id: { eq: $account_id }
          provider_name: { ilike: $provider_name }
          provider_display_name: { ilike: $provider_display_name }
          spend_cap: { eq: $spend_cap }
          provider_category: { eq: $provider_category }
          status: { eq: $status }
          status: { neq: $not_status }
          website: { ilike: $website }
          phone: { ilike: $phone }
          parent_id: { eq: $parent_id }
        }
      }
    ) {
      edges {
        cursor
        node {
          ...CoreBrandFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_BRAND_BY_NODE_ID = gql`
  ${CORE_BRAND_FIELDS}
  query GetBrandByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on brand {
        ...CoreBrandFields
      }
    }
  }
`;

export const GET_BRAND_BY_ID = gql`
  ${CORE_BRAND_FIELDS}
  query GetBrandById($id: Int!) {
    brandCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreBrandFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_BRAND = gql`
  ${CORE_BRAND_FIELDS}
  mutation CreateBrand(
    $salesforce_provider_id: String
    $active: Boolean
    $provider_name: String
    $provider_display_name: String
    $course_guide_hosted: Boolean
    $post_enquiry_sms_enabled: Boolean
    $post_enquiry_sms_content: String
    $email_leads_enabled: Boolean
    $lead_notification_email: String
    $airtable_id: String
    $integration_enabled: Boolean
    $about_provider: String
    $logo: String
    $provider_lead_bundles_tnc: Boolean
    $provider_ecomm_tnc: Boolean
    $valid_cc_for_provider_ecomm: Boolean
    $auto_pausing_enabled: Boolean
    $spend_cap: BigFloat
    $remaining_spend: BigFloat
    $provider_category: String
    $budget_alerts_email: String
    $send_leads_to_countries: String
    $all_courses_published_client: Boolean
    $all_courses_published_candlefox: Boolean
    $api_id_required: Boolean
    $grouping_required: Boolean
    $course_code_required: Boolean
    $account_id: Int!
    $parent_id: Int
    $status: String
    $author: UUID
    $salesforce_sync_status_courses: String
    $wordpress_sync_status: String
    $provider_pause_reason: String
    $is_migrated_from_salesforce: Boolean
    $should_trigger_notification: Boolean
    $qc_note: String
    $last_published_id: Int
    $qc_status: String
    $website: String
    $phone: String
    $system_pause_reason: String
    $rto_code: String
  ) {
    insertIntobrandCollection(
      objects: [
        {
          salesforce_provider_id: $salesforce_provider_id
          active: $active
          provider_name: $provider_name
          provider_display_name: $provider_display_name
          course_guide_hosted: $course_guide_hosted
          post_enquiry_sms_enabled: $post_enquiry_sms_enabled
          post_enquiry_sms_content: $post_enquiry_sms_content
          email_leads_enabled: $email_leads_enabled
          lead_notification_email: $lead_notification_email
          airtable_id: $airtable_id
          integration_enabled: $integration_enabled
          about_provider: $about_provider
          logo: $logo
          provider_lead_bundles_tnc: $provider_lead_bundles_tnc
          provider_ecomm_tnc: $provider_ecomm_tnc
          valid_cc_for_provider_ecomm: $valid_cc_for_provider_ecomm
          auto_pausing_enabled: $auto_pausing_enabled
          spend_cap: $spend_cap
          remaining_spend: $remaining_spend
          provider_category: $provider_category
          budget_alerts_email: $budget_alerts_email
          send_leads_to_countries: $send_leads_to_countries
          all_courses_published_client: $all_courses_published_client
          all_courses_published_candlefox: $all_courses_published_candlefox
          api_id_required: $api_id_required
          grouping_required: $grouping_required
          course_code_required: $course_code_required
          account_id: $account_id
          parent_id: $parent_id
          status: $status
          author: $author
          salesforce_sync_status_courses: $salesforce_sync_status_courses
          wordpress_sync_status: $wordpress_sync_status
          provider_pause_reason: $provider_pause_reason
          is_migrated_from_salesforce: $is_migrated_from_salesforce
          should_trigger_notification: $should_trigger_notification
          qc_note: $qc_note
          last_published_id: $last_published_id
          qc_status: $qc_status
          website: $website
          phone: $phone
          system_pause_reason: $system_pause_reason
          rto_code: $rto_code
        }
      ]
    ) {
      affectedCount
      records {
        ...CoreBrandFields
      }
    }
  }
`;

export const UPDATE_BRAND_BY_ID = gql`
  ${CORE_BRAND_FIELDS}
  mutation UpdateBrandById(
    $nodeId: ID
    $id: Int
    $salesforce_provider_id: String
    $active: Boolean
    $provider_name: String
    $provider_display_name: String
    $course_guide_hosted: Boolean
    $post_enquiry_sms_enabled: Boolean
    $post_enquiry_sms_content: String
    $email_leads_enabled: Boolean
    $lead_notification_email: String
    $airtable_id: String
    $integration_enabled: Boolean
    $about_provider: String
    $logo: String
    $provider_lead_bundles_tnc: Boolean
    $provider_ecomm_tnc: Boolean
    $valid_cc_for_provider_ecomm: Boolean
    $auto_pausing_enabled: Boolean
    $spend_cap: BigFloat
    $remaining_spend: BigFloat
    $provider_category: String
    $budget_alerts_email: String
    $send_leads_to_countries: String
    $all_courses_published_client: Boolean
    $all_courses_published_candlefox: Boolean
    $api_id_required: Boolean
    $grouping_required: Boolean
    $course_code_required: Boolean
    $account_id: Int!
    $parent_id: Int
    $status: String
    $author: UUID
    $salesforce_sync_status_courses: String
    $wordpress_sync_status: String
    $provider_pause_reason: String
    $is_migrated_from_salesforce: Boolean
    $should_trigger_notification: Boolean
    $qc_note: String
    $last_published_id: Int
    $qc_status: String
    $website: String
    $phone: String
    $system_pause_reason: String
    $rto_code: String
  ) {
    updatebrandCollection(
      set: {
        salesforce_provider_id: $salesforce_provider_id
        active: $active
        provider_name: $provider_name
        provider_display_name: $provider_display_name
        course_guide_hosted: $course_guide_hosted
        post_enquiry_sms_enabled: $post_enquiry_sms_enabled
        post_enquiry_sms_content: $post_enquiry_sms_content
        email_leads_enabled: $email_leads_enabled
        lead_notification_email: $lead_notification_email
        airtable_id: $airtable_id
        integration_enabled: $integration_enabled
        about_provider: $about_provider
        logo: $logo
        provider_lead_bundles_tnc: $provider_lead_bundles_tnc
        provider_ecomm_tnc: $provider_ecomm_tnc
        valid_cc_for_provider_ecomm: $valid_cc_for_provider_ecomm
        auto_pausing_enabled: $auto_pausing_enabled
        spend_cap: $spend_cap
        remaining_spend: $remaining_spend
        provider_category: $provider_category
        budget_alerts_email: $budget_alerts_email
        send_leads_to_countries: $send_leads_to_countries
        all_courses_published_client: $all_courses_published_client
        all_courses_published_candlefox: $all_courses_published_candlefox
        api_id_required: $api_id_required
        grouping_required: $grouping_required
        course_code_required: $course_code_required
        account_id: $account_id
        parent_id: $parent_id
        status: $status
        author: $author
        salesforce_sync_status_courses: $salesforce_sync_status_courses
        wordpress_sync_status: $wordpress_sync_status
        provider_pause_reason: $provider_pause_reason
        is_migrated_from_salesforce: $is_migrated_from_salesforce
        should_trigger_notification: $should_trigger_notification
        qc_note: $qc_note
        last_published_id: $last_published_id
        qc_status: $qc_status
        website: $website
        phone: $phone
        system_pause_reason: $system_pause_reason
        rto_code: $rto_code
      }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreBrandFields
      }
    }
  }
`;

export const DELETE_BRAND_BY_ID = gql`
  mutation DeleteBrandById($nodeId: ID, $id: Int) {
    deleteFrombrandCollection(filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }) {
      affectedCount
      records {
        id
      }
    }
  }
`;
