import type { UserMetadata } from '@supabase/supabase-js';

import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_PROFILE_BY_ID,
  DELETE_PROFILE_BY_ID,
  UPDATE_PROFILE_BY_ID,
  GET_PROFILE_BY_NODE_ID,
} from '@api/profile';

import { signUp } from 'src/auth/context/supabase';

import type { User } from '../../auth/types';

// ** User initial state
const userInitialState = {
  nodeId: '',
  id: '',
  display_name: '',
  first_name: null,
  last_name: null,
  email: '',
  phone_number: null,
  date_of_birth: null,
  avatar_url: null,
  gender: null,
  opt_in_notifications: null,
  account_id: 0,
  account: {
    nodeId: null,
    id: 0,
    salesforce_account_id: null,
    account_name: '',
    abn: null,
    billing_country: null,
    billing_street: null,
    billing_city: null,
    billing_state: null,
    billing_postal_code: null,
    first_name: null,
    last_name: null,
    contact_email: null,
    invoice_email: null,
    phone: null,
    provider_type: null,
    rto_code: null,
    managed_through_self_service: false,
    new_business_flag: false,
    parent_id: null,
    status: null,
    author: null,
    salesforce_sync_status_account: null,
    salesforce_sync_status_brands: null,
    is_migrated_from_salesforce: false,
    should_trigger_notification: false,
    qc_note: null,
    last_published_id: null,
    qc_status: null,
    system_note: null,
    is_fully_synced: null,
  },
};

// ** Fetch Single User
export const fetchSingleUser = createAsyncThunk<User, { nodeId: string }, {}>(
  'user/fetchSingleUser',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_PROFILE_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single User By ID
export const fetchSingleUserById = createAsyncThunk<User, { id: string }, {}>(
  'user/fetchSingleUserById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_PROFILE_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create User
export const createUser = createAsyncThunk<UserMetadata | User, Partial<UserMetadata | User>, {}>(
  'user/createUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await signUp({
        email: userData.email,
        password: userData.password,
        firstName: userData.first_name,
        lastName: userData.last_name,
        accountId: userData.account_id,
      });

      return response?.data?.user;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update User
export const updateUser = createAsyncThunk<User, Partial<User>, {}>(
  'user/updateUser',
  async (accountData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_PROFILE_BY_ID,
        variables: { ...accountData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete User
export const deleteUser = createAsyncThunk<User, Partial<User>, {}>(
  'user/deleteUser',
  async (accountData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_PROFILE_BY_ID,
        variables: { ...accountData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleUserSlice = createSlice({
  name: 'user',
  initialState: {
    user: <UserMetadata | User>{
      ...userInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleUser.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.user = node;
      })
      .addCase(fetchSingleUserById.fulfilled, (state, { payload }) => {
        const {
          profilesCollection: { edges },
        }: any = payload;

        state.user = edges[0].node;
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        const { user_metadata }: any = payload;

        state.user = user_metadata;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        const {
          updateprofilesCollection: { records },
        }: any = payload;

        state.user = records[0];
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        const {
          deleteFromprofilesCollection: { records },
        }: any = payload;

        state.user = records[0];
      });
  },
});

export default singleUserSlice.reducer;
