import type { UserMetadata } from '@supabase/supabase-js';

import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_PROFILE_BY_ID, UPDATE_PROFILE_BY_ID } from '@api/profile';

import { getloggedInUser, signInWithPassword, updateSupabaseUser } from 'src/auth/context/supabase';

import type { User } from './types';

// ** User initial state
const userInitialState = {
  nodeId: '',
  id: '',
  display_name: '',
  first_name: null,
  last_name: null,
  email: '',
  phone_number: null,
  date_of_birth: null,
  avatar_url: null,
  gender: null,
  opt_in_notifications: null,
  account_id: 0,
  account: {
    nodeId: null,
    id: 0,
    salesforce_account_id: null,
    account_name: '',
    abn: null,
    billing_country: null,
    billing_street: null,
    billing_city: null,
    billing_state: null,
    billing_postal_code: null,
    first_name: null,
    last_name: null,
    contact_email: null,
    invoice_email: null,
    phone: null,
    provider_type: null,
    rto_code: null,
    managed_through_self_service: false,
    new_business_flag: false,
    parent_id: null,
    status: null,
    author: null,
    salesforce_sync_status_account: null,
    salesforce_sync_status_brands: null,
    is_migrated_from_salesforce: false,
    should_trigger_notification: false,
    qc_note: null,
    last_published_id: null,
    qc_status: null,
    system_note: null,
    is_fully_synced: null,
  },
};

// ** Sign In
export const signIn = createAsyncThunk<
  UserMetadata | User,
  { email: string; password: string },
  {}
>('authedUser/signIn', async (userData, { rejectWithValue }) => {
  try {
    const response = await signInWithPassword({
      email: userData.email,
      password: userData.password,
    });

    return response?.data?.session?.user.user_metadata;
  } catch (err) {
    const error: any = err; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data);
  }
});

// ** Get Logged In User Profile
export const getUser = createAsyncThunk('authedUser/getUser', async () => {
  try {
    const response = await getloggedInUser();
    const userId = response?.data?.user?.id;

    const { data } = await apolloClient.query({
      query: GET_PROFILE_BY_ID,
      variables: { id: userId },
      fetchPolicy: 'no-cache',
    });

    return { ...data.profilesCollection.edges[0].node, email: response?.data?.user?.email };
  } catch (err) {
    const error: any = err; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return error.response.data;
  }
});

// ** Update User Profile
export const editUser = createAsyncThunk<UserMetadata | User, Partial<UserMetadata | User>, {}>(
  'authedUser/editUser',
  async (userData, { rejectWithValue }) => {
    try {
      // Update Supabase Auth info
      await updateSupabaseUser({
        firstName: userData.first_name,
        lastName: userData.last_name,
      });

      const { data } = await apolloClient.mutate({
        mutation: UPDATE_PROFILE_BY_ID,
        variables: { ...userData },
        fetchPolicy: 'no-cache',
      });

      return data.updateprofilesCollection.records[0];
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const authedUserSlice = createSlice({
  name: 'authedUser',
  initialState: {
    authedUser: <UserMetadata>{
      ...userInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.authedUser = payload;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.authedUser = payload;
      })
      .addCase(editUser.fulfilled, (state, { payload }) => {
        // Reset user state.
        state.authedUser = { ...userInitialState };

        state.authedUser = payload;
      });
  },
});

export default authedUserSlice.reducer;
