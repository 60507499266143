import apolloClient from '@lib/apollo';
import { GET_BRANDS, FILTERED_BRANDS } from '@api/brand';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { Brand } from './types';

// ** Brand initial state
const brandsInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        salesforce_provider_id: null,
        active: null,
        provider_name: null,
        provider_display_name: null,
        course_guide_hosted: null,
        post_enquiry_sms_enabled: false,
        post_enquiry_sms_content: null,
        email_leads_enabled: false,
        lead_notification_email: null,
        airtable_id: null,
        integration_enabled: false,
        about_provider: null,
        logo: null,
        provider_lead_bundles_tnc: false,
        provider_ecomm_tnc: false,
        valid_cc_for_provider_ecomm: false,
        auto_pausing_enabled: false,
        spend_cap: null,
        remaining_spend: null,
        provider_category: null,
        budget_alerts_email: null,
        send_leads_to_countries: null,
        all_courses_published_client: false,
        all_courses_published_candlefox: false,
        api_id_required: false,
        grouping_required: false,
        course_code_required: false,
        account_id: 0,
        parent_id: null,
        status: null,
        author: null,
        salesforce_sync_status_courses: null,
        wordpress_sync_status: null,
        provider_pause_reason: null,
        is_migrated_from_salesforce: false,
        should_trigger_notification: false,
        qc_note: null,
        last_published_id: null,
        qc_status: null,
        website: null,
        phone: null,
        system_note: null,
        system_pause_reason: null,
        rto_code: null,
        account: {
          nodeId: null,
          id: 0,
          salesforce_account_id: null,
          account_name: '',
          abn: null,
          billing_country: null,
          billing_street: null,
          billing_city: null,
          billing_state: null,
          billing_postal_code: null,
          first_name: null,
          last_name: null,
          contact_email: null,
          invoice_email: null,
          phone: null,
          provider_type: null,
          rto_code: null,
          managed_through_self_service: false,
          new_business_flag: false,
          parent_id: null,
          status: null,
          author: null,
          salesforce_sync_status_account: null,
          salesforce_sync_status_brands: null,
          is_migrated_from_salesforce: false,
          should_trigger_notification: false,
          qc_note: null,
          last_published_id: null,
          qc_status: null,
          system_note: null,
          is_fully_synced: null,
        },
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface BrandsState {
  edges: {
    cursor: string;
    node: Partial<Brand>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch Brands
export const fetchBrands = createAsyncThunk<Brand, Partial<Brand>, {}>(
  'brands/fetchBrands',
  async (brandData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_BRANDS,
        variables: { ...brandData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered Brands
export const fetchFilteredBrands = createAsyncThunk<Brand, any, {}>(
  'brands/fetchFilteredBrands',
  async (brandData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_BRANDS,
        variables: { ...brandData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const brandsSlice = createSlice({
  name: 'brands',
  initialState: {
    brands: <BrandsState>{
      ...brandsInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.fulfilled, (state, { payload }) => {
        const { brandCollection }: any = payload;

        state.brands = brandCollection;
      })
      .addCase(fetchFilteredBrands.fulfilled, (state, { payload }) => {
        const { brandCollection }: any = payload;

        state.brands = brandCollection;
      });
  },
});

export default brandsSlice.reducer;
