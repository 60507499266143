import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  exp: number;
}

export const isTokenValid = (token: string): boolean => {
  try {
    const decoded = jwtDecode<DecodedToken>(token);
    const currentTime = Date.now() / 1000; // Convert to UNIX epoch

    return decoded.exp > currentTime;
  } catch (error) {
    return false;
  }
};
